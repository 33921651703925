import { render, staticRenderFns } from "./ExamTabGeneralInformation.vue?vue&type=template&id=2bca1ecf&scoped=true"
import script from "./ExamTabGeneralInformation.vue?vue&type=script&lang=js"
export * from "./ExamTabGeneralInformation.vue?vue&type=script&lang=js"
import style0 from "./ExamTabGeneralInformation.vue?vue&type=style&index=0&id=2bca1ecf&prod&scoped=true&lang=css"
import style1 from "./ExamTabGeneralInformation.vue?vue&type=style&index=1&id=2bca1ecf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bca1ecf",
  null
  
)

export default component.exports