<template>

    <b-col 
        cols="12"
    >

        <hr>

        <b-button
            @click="openModal()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"	
            variant="primary"	
            size="md"
            class="mb-1"
            block
        > 
            <feather-icon size="1x" icon="FileTextIcon" /> 
            SELECCIONAR PREGUNTAS
        </b-button>

        <b-col 
            cols="12"
        >

            <template v-if="loading">
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mb-1"></b-spinner>
                    <div><b>CARGANDO</b></div>
                </div>
            </template>

            <template v-else>

                <template v-if="selectedQuestions.length">

                    <h5><strong>PREGUNTAS SELECCIONADAS ({{ selectedQuestions.length }})</strong></h5>
                
                    <b-list-group-item
                        v-for="(item, index) in selectedQuestions"
                        :key="index"
                        tag="li"
                    >
                        <b-row>

                            <b-col 
                                cols="12" 
                                sm="10"
                            >
                                <b-card-text class="mb-0">
                                    <strong>({{ index + 1 }}) {{item.code }}: </strong>{{ item.question }}
                                </b-card-text>

                                <b-badge :variant="item.question_type.color">
                                    {{ item.question_type.name }}
                                </b-badge>
                            </b-col>

                            <b-col 
                                cols="12" 
                                sm="2" 
                                class="text-left text-sm-right"
                            >
                                <b-button				
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    v-b-tooltip.hover.v-danger
                                    size="sm"
                                    variant="outline-danger"
                                    title="ELIMINAR"
                                    @click="removeSelectedQuestion(item.code, index)"
                                >
                                    <feather-icon icon="TrashIcon"  />
                                </b-button>
                            </b-col>

                        </b-row>
                    </b-list-group-item>

                </template>

                <template v-else>

                    <b-alert variant="warning" class="mb-0" show>
                        <div class="alert-body">
                            <span>!SIN PREGUNTAS! NO HA SELECCIONADO NINGUNA PREGUNTA HASTA EL MOMENTO.</span>
                        </div>
                    </b-alert>

                </template>

            </template>

        </b-col>

        <!-- MODAL -->
        <b-modal
            ref="modal"
            size="xl"
            centered
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"
            title="SELECCIÓN DE PREGUNTAS"
            @hide="closeModal"
            no-close-on-backdrop
            no-close-on-esc
        >
            <b-alert variant="info" show>
                <div class="alert-body">
                    <ul class="mb-0">
                        <li>
                            Seleccione las preguntas que se incluirán en el examen.
                        </li>
                        <li v-if="!questions.length">
                            No existen preguntas, puede crearlas desde <b-link :to="{name: 'questions-list'}"><strong>Lista de Preguntas</strong></b-link>.
                        </li>
                    </ul>
                </div>
            </b-alert>
            
            <b-card-text class="my-2">
                
                <!-- SORTING  -->
                <b-form-group
                    label="Ordenar"
                    label-size="sm"
                    label-align-sm="left"
                    label-cols-sm="2"
                    label-for="sortBySelect"
                    class="mb-md-0"
                >
                    <b-input-group
                        size="sm"
                    >
                        <b-form-select
                            id="sortBySelect"
                            v-model="sortBy"
                            :options="sortOptions"
                        >
                            <template #first>
                                <option value="">Ninguno</option>
                            </template>
                        </b-form-select>
                        <b-form-select
                            v-model="sortDesc"
                            size="sm"
                            :disabled="!sortBy"
                        >
                            <option :value="false">ASC</option>
                            <option :value="true">DESC</option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>

                <!-- FILTER -->
                <b-form-group
                    label="Fitrar"
                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0 mt-1"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Buscar ..."
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                Limpiar
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-row 
                    align-v="center" 
                    align-h="between"
                >
                    <b-col
                        cols="3"
                        class="my-1"
                    >
                        <b-form-group class="mb-0">
                            <label class="d-inline-block text-sm-left mr-50">Por página</label>
                            <b-form-select
                                id="perPageSelect"
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                class="w-50"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- TABLE -->
                <b-table
                    ref="table"
                    class="position-relative"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="questions"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    striped
                    hover
                    responsive
                    show-empty
                    empty-filtered-text="No hay registros que coincidan con su filtro"
                    empty-text="No se encontraron registros coincidentes"
                >

                    <template #cell(#)="row">
                        <b-form-checkbox
                            v-model="markedQuestions"
                            :value="row.item"
                            class="p-0"
                        >
                        </b-form-checkbox>
                    </template>
                    
                    <template #cell(code)="data">
                        <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.code }}
                        </span>
                    </template>

                    <template #cell(question)="data">
                        <span class="d-block text-nowrap">
                            {{ data.item.question.length > 90 ? `${data.item.question.substring(0, 90)}...` : data.item.question }}
                        </span>
                    </template>

                    <template #cell(question_type_name)="data">
                        <span class="d-block text-nowrap">
                            <b-badge :variant="data.item.question_type_color">
                                {{ data.item.question_type_name }}
                            </b-badge>
                        </span>
                    </template>

                </b-table>

                <!-- PAGINATION -->
                <div>

                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>

                </div>

            </b-card-text>

            <template #modal-footer="{ cancel }">
                <b-button variant="primary" @click="addMarkedQuestions">
                    AGREGAR
                </b-button>
                <b-button variant="outline-secondary" @click="cancel">
                    CERRAR
                </b-button>
            </template>
        </b-modal>

    </b-col>
            
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ref, onBeforeMount, computed } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import Ripple from 'vue-ripple-directive';
    import axios from '@axios';

    export default {
        directives: {
            Ripple
        },
        props: {
            selectedExamQuestions: {
                type: Array,
                default: []
            }
        },
        setup(props) {

            // USE TOAST
			const toast = useToast();

            // REFS
            const selectedQuestions = ref(props.selectedExamQuestions);
            const loading = ref(true);
            const questions = ref([]);
            const modal = ref(null);

            const markedQuestions = ref([]);
            const fields = ref([]);

            const totalRows = ref(1);
			const currentPage = ref(1);
			const perPage = ref(10);
			const pageOptions = ref([5, 10, 15, 20]);
			const sortBy = ref('');
			const sortDesc = ref(false);
			const sortDirection = ref('asc');
			const filter = ref(null);
			const filterOn = ref([]);

            onBeforeMount( () => {

                const styleColum = {
                    thClass: 'text-center',
                    tdClass: 'text-center'
                };

                fields.value = [{
                    label: '#',
                    key: '#',
                    ...styleColum
                }, {
                    label: 'CÓDIGO',
                    sortable: true,
                    key: 'code',
                    ...styleColum
                }, {
                    label: 'PREGUNTA',
                    sortable: true,
                    key: 'question',
                    thClass: styleColum.tdClass
                }, {
                    label: 'TIPO DE PREGUNTA',
                    sortable: true,
                    key: 'question_type_name',
                    ...styleColum
                }];

                loading.value = false;

            });

            // METHODS
            const openModal = async () => {

				loading.value = true;

                await getQuestions();
                
				totalRows.value = questions.value.length;
				currentPage.value = 1;
				sortBy.value = '';
				sortDesc.value = false;
				sortDirection.value = 'asc';
				filter.value = null;

				modal.value.show();

			};

            const sortOptions = computed(() => {
                return fields.value
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            });

            const onFiltered = (filteredItems) => {
                totalRows.value = filteredItems.length;
                currentPage.value = 1;
            };

            const getQuestions = async () => 
            {
                const selected = selectedQuestionsArray();
				const { data } = await axios.get(`/selects/questions?selected=${selected}`);
                
                questions.value = data.questions;
			};

            const removeSelectedQuestion = (code, index) => {

                selectedQuestions.value.splice(index, 1);

                toast({
                    component: ToastificationContent,
                    props: {
                        title: `Pregunta seleccionada: "${code}" ha sido eliminada correctamente.`,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });

            };

            const closeModal = () => {

                markedQuestions.value = [];
				modal.value.hide();

                loading.value = false;

            }

            const addMarkedQuestions = () => {

                markedQuestions.value.map(item => {

                    const selected = selectedQuestions.value.find(i => i.code === item.code);

                    if (!selected) selectedQuestions.value.push({ id: item.id, code: item.code, question: item.question, question_type: { name: item.question_type_name, color: item.question_type_color } });

                });

                toast({
                    component: ToastificationContent,
                    props: {
                        title: `Se han agregado "${markedQuestions.value.length}" preguntas correctamente.`,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });

                closeModal();

            };

            const selectedQuestionsArray = () => {
                const array = selectedQuestions.value.map(item => item.id);
                const unique = [ ...new Set(array) ];

                return unique.sort((a, b) => a - b);
            };

            return {
                // REFS
                selectedQuestions,
                markedQuestions,
                loading,
                modal,
                fields,
                questions,

                totalRows,
                currentPage,
                perPage,
                pageOptions,
                sortBy,
                sortDesc,
                sortDirection,
                filter,
                filterOn,

                // METHODS
                selectedQuestionsArray,
                removeSelectedQuestion,
                addMarkedQuestions,
                sortOptions,
                onFiltered,
                openModal,
                closeModal
            }
        }
    }

</script>