<template>	
    <div>

	    <!-- Alert: No item found -->
	    <b-alert
            variant="danger"
            :show="examData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos del examen</h4>
            <div class="alert-body">
                No se encontró ningún examen con este id.
            </div>
        </b-alert>

		<exam-tab-general-information
			v-if="examData"
			class="mt-2 pt-75"
			:exam-data="examData"
		/>

    </div>
</template>

<script>

	import ExamTabGeneralInformation from './ExamTabGeneralInformation';
	import { ref, onUnmounted } from '@vue/composition-api';
	import examStoreModule from '../examStoreModule';
	import router from '@/router';
	import store from '@/store';

	export default {
		components: {
			ExamTabGeneralInformation
		},
		setup() {

			// REFS
			const examData = ref(null);

			const EXAM_APP_STORE_MODULE_NAME = 'app-exam'

			// REGISTER MODULE
			if (!store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.registerModule(EXAM_APP_STORE_MODULE_NAME, examStoreModule)

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.unregisterModule(EXAM_APP_STORE_MODULE_NAME)
			});

			store.dispatch('app-exam/fetchExam', { slug: router.currentRoute.params.slug })
				.then(response => {
					if (response.data === 'error') {
                        examData.value = undefined
                    } else {
                        examData.value = response.data.exam;
                    }
				})
				.catch(error => {
					if (error.response.status === 404) {
						examData.value = undefined
					}
				});

			const goBack = () => router.go(-1);
			
			return {
				// DATA
				examData,

				// METHODS
				goBack
			}
		}
	}

</script>