<template>
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <!-- TITLE -->
                            <b-col 
                                cols="12" 
                                md="4"
                            >
                                <b-form-group
                                    label="TÍTULO"
                                    label-for="title"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="título"
                                    >
                                        <b-form-input
                                            id="title"
                                            v-model="formData.title"
                                            placeholder="Ingrese título del examen ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                                                        
                            <!-- TIME -->
                            <b-col 
                                cols="12" 
                                md="4"
                            >
                                <b-form-group
                                    label="DURACIÓN (MINUTOS)"
                                    label-for="time"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required|positive|min_value:1"
                                        name="duración"
                                    >
                                        <b-form-input
                                            id="time"
                                            placeholder="0"
                                            type="number"
                                            v-model="formData.time"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- NUMBER OF RANDOM QUESTIONS -->
                            <b-col 
                                cols="12"
                                md="4"
                            >
                                <b-form-group
                                    label="NÚMERO DE PREGUNTAS ALEATORIAS"
                                    label-for="qty_random_questions"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required|positive|min_value:1"
                                        name="número de preguntas aleatorias"
                                    >
                                        <b-form-input
                                            id="qty_random_questions"
                                            placeholder="0"
                                            type="number"
                                            v-model="formData.qty_random_questions"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- TOTAL SCORE -->
                            <b-col 
                                cols="12" 
                                md="4"
                            >
                                <b-form-group
                                    label="PUNTAJE TOTAL"
                                    label-for="total_score"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        id="total_score"
                                        :value="formData.total_score"
                                        class="font-weight-bold text-primary"
                                        disabled
                                        readonly
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- QUESTION SCORE -->
                            <b-col 
                                cols="12" 
                                md="4"
                            >
                                <b-form-group
                                    label="PUNTAJE POR PREGUNTA"
                                    label-for="question_score"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        id="question_score"
                                        :value="questionScore"
                                        class="font-weight-bold text-primary"
                                        disabled
                                        readonly
                                    />
                                </b-form-group>
                            </b-col>

                            <BButtonQuestions
                                ref="selectedQuestions"
                                :selected-exam-questions="questions"
                            />
                            
                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="ACTUALIZAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                            <b-button
                                v-if="!processing"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                class="ml-2"
                                @click="cancelAction()"
                            >
                                CANCELAR
                            </b-button>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonQuestions from '@core/components/b-button-questions/BButtonQuestions.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, computed, onUnmounted, onBeforeMount } from '@vue/composition-api';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, positive, min_value } from '@validations';
    import { useToast } from 'vue-toastification/composition';
    import examStoreModule from '../examStoreModule';
    import VueSweetalert2 from 'vue-sweetalert2';
    import Ripple from 'vue-ripple-directive';
    import router from '@/router';
    import store from '@/store';
    import Vue from 'vue';
    import 'animate.css';

    Vue.use(VueSweetalert2);

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonQuestions,
            BButtonLoading,

            min_value,
            required,
            positive
        },
        directives: {
            Ripple
        },
        props: {
            examData: {
                type: Object,
                required: true
            }
        },
		setup(props) {

            // USE TOAST
			const toast = useToast();

            // REFS
            const examData = ref(props.examData);
            const selectedQuestions = ref(null);
            const questions = ref([]);
            const refForm = ref(null);

            const processing = ref(false);
            const loading = ref(false);

            const formData = ref({
				title: null,
                time: null,
                qty_random_questions: null,
                total_score: null
			});

            const snowOption = ref({
                theme: 'snow',
                placeholder: 'Escriba aquí...'
            });

			const EXAM_APP_STORE_MODULE_NAME = 'app-exam';

            // REGISTER MODULE
            if (!store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.registerModule(EXAM_APP_STORE_MODULE_NAME, examStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted(() => {
                if (store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.unregisterModule(EXAM_APP_STORE_MODULE_NAME);
            });

            onBeforeMount( async () => {
               setData();
            });

            const setData = () => {

                questions.value = examData.value.questions;
                
                formData.value = {
                    title: examData.value.title,
                    time: examData.value.time,
                    qty_random_questions: examData.value.qty_random_questions,
                    total_score: examData.value.total_score
                }

            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                const questions = selectedQuestions.value.selectedQuestionsArray();

                const { qty_random_questions } = formData.value;
                let message = null, error = false;
                
                if (!error && questions.length <= 0) 
                {
                    message = 'Debe seleccionar al menos una pregunta.';
                    error = true;
                }

                if (!error && qty_random_questions > questions.length) 
                {
                    message = 'El número de preguntas aleatorias no puede ser mayor a la cantidad de preguntas seleccionadas.';
                    error = true;
                }
                
                if (error) 
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });

                    return;
                }

                processing.value = true;

                const { title, time } = formData.value;

				const payload = {
                    title,
                    time,
                    qty_random_questions,
                    questions: questions.join(',')
				}

                const id = examData.value.id;

				store.dispatch('app-exam/updateExam', { id, payload })
					.then( response => {
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

					})
					.catch( (err) => {

						const message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del examen.';						

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                        
					})
					.finally( () =>  processing.value = false);
			};

            const questionScore = computed(() => (formData.value.qty_random_questions ? (formData.value.total_score / formData.value.qty_random_questions).toFixed(2) : 0));

            const cancelAction = () => {

                Vue.swal({
					title: '¿Está seguro(a) de salir? Perderá todo el progreso.',
					text: '¡Si no lo está, puede cancelar la acción!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, continuar!',
					cancelButtonText: 'cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) router.push({ name: 'courses-edit', params: { slug: examData.value.course.slug }, query: { tab: 'exams' } });

                });

            }

			return {
                // REFS
                selectedQuestions,
                processing,
                refForm,
                loading,

				formData,
                snowOption,
                questions,

                // COMPUTED
                questionScore,

                // METHODS
                onSubmit,
                cancelAction
			}
		}
    }

</script>

<style scoped>

    .font-weight-bold {
        font-weight: bold !important;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>